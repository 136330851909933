<template>
  <v-app id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <transition name="fade-transform" mode="out-in">
      <keep-alive>
        <router-view/>
      </keep-alive>
    </transition>
  </v-app>
</template>
<script>
  export default {
    mounted() {
      window.extAsyncInit = function() {
        
      };
    }
  }
</script>
<style lang="scss">
body {
  background: #f1f1f1;
}
</style>

<style>
  @import './assets/app.css';
  @import './assets/toast.css';
</style>
