import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            csb_yellow: '#faa634',
            csb_purple: '#4f2684',
          },
        },
      },
    })